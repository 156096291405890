@import "GlobalStyles/variables";

:global(.MuiAutocomplete-popper *) {
  background-color: $citiesBackground;
}

:global(.MuiButton-startIcon):not(#\09) {
  margin:0 -4px 0 8px;
}

//// handle escaping bug when mui list items suddenly loses style and appear in one line
//// also AppBar mobile burger
//:global(.MuiList-root.MuiList-padding.MuiMenu-list) {
//  width: calc(100% + 0px);
//  list-style: none;
//  margin: 0;
//  padding: 0;
//  position: relative;
//  padding-top: 8px;
//  padding-bottom: 8px;
//  outline: 0;
//}
